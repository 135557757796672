<script lang="ts" setup>
import { mdiClockOutline, mdiCarArrowLeft, mdiCarArrowRight } from "mdi-js-es";
import type { VTextField } from "vuetify/components";

const props = defineProps<{
  pickerProps?: any;
  timeOptions: string[];
  vertical?: boolean;
}>();

const timeStart = defineModel<string>("start");
const timeEnd = defineModel<string>("end");

const fieldRef = ref<InstanceType<typeof VTextField>>();

defineExpose({
  focus: () => fieldRef.value?.focus(),
});

defineOptions({
  inheritAttrs: false,
});
</script>

<template>
  <div class="flex-1 gap-4 hidden lg:flex" :class="{ 'flex-col': vertical, 'rangepicker-compact': timeOptions.length > 0 }">
    <div class="flex flex-1">
      <VTextField id="arrival_day" :label="$t('fields.arrival')" :modelValue="pickerProps?.inputValue.start" v-on="pickerProps?.inputEvents.start" @click="pickerProps?.showPopover" readonly ref="fieldRef">
        <template #prepend-inner>
          <VIcon start :icon="mdiCarArrowLeft" color="gray"></VIcon>
        </template>
      </VTextField>
      <VSelect id="arrival_time" v-model="timeStart" v-if="timeOptions.length > 0" :items="timeOptions" class="flex-1 min-w-[140px]">
        <template #prepend-inner>
          <VIcon start :icon="mdiClockOutline" color="gray"></VIcon>
        </template>
      </VSelect>
    </div>

    <div class="flex flex-1">
      <VTextField id="departure_day" :label="$t('fields.departure')" :modelValue="pickerProps?.inputValue.end" v-on="pickerProps?.inputEvents.end" @click="pickerProps?.showPopover">
        <template #prepend-inner>
          <VIcon start :icon="mdiCarArrowRight" color="gray"></VIcon>
        </template>
      </VTextField>
      <VSelect id="departure_time" v-model="timeEnd" v-if="timeOptions.length > 0" :items="timeOptions" class="flex-1 min-w-[140px]">
        <template #prepend-inner>
          <VIcon start :icon="mdiClockOutline" color="gray"></VIcon>
        </template>
      </VSelect>
    </div>
  </div>
</template>